
/* Fuentes */

/* .roboto { 
    font-weight: 400;
} 

.roboto-flex {
    font-family: 'roboto-flex', sans-serif;
    font-weight: 700;
}
.roboto-mono {
    font-family: 'roboto-mono', monospace;
    font-weight: 400;
} */

body{
    font-family: 'roboto', sans-serif;
    background-color: #0080C8;
}
body.dark{ 
    background-color: #184052;
}
p{
    @apply text-white font-roboto-mono text-[4vw] min-[420px]:text-[15px] 
     
    sm:text-[16px] xl:text-[18px] min-[1440px]:text-[22px] font-[500]
     leading-[1.32525em] mb-[20px]; 
}

.bg-site{
    background: #fff url('../public/img/bg-mobile.svg') no-repeat;
    background-size: 100% auto;
    background-position: right top;
}
.dark .bg-site{
    background: #56819F url('../public/img/bg-dark-mobile.svg') no-repeat; 
}

.only-desktop{
    display: none ;
}

.svg-max-full svg{
    display: block;
    max-width: 100%;
    height: auto;
}

.is-about{
    transform: translateX(-50%);
    transition: all .3s;  
}

/* popup */
body.open-popup{
    position: fixed;
    height: 100vh;
    overflow: hidden;
    width: 100%;
}
/* Menu hamburguesa */
.hamburger-menu{
    width: 40px;
    height: 30px;
    background: transparent; 
    outline: 0;
    border: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.hamburger-menu span{
    display: block;
    background: #0080C8;  
    width: 100%;
    height: 5px;
    position: absolute;
    top: 50%;
    margin-top: -2.5px;
    transition: opacity .2s ease-out .2s;
    border-radius: 2px;
    
}
.dark .hamburger-menu span{
   background-color: #fff;
}
body.open-popup .hamburger-menu span{
    background-color: #fff;
}
.hamburger-menu:before, .hamburger-menu:after{
    content: "";
    border-radius: 2px;
    position: absolute;
    box-sizing: border-box;
    display: block;
    float: left; 
    transition: all 0.3s ease-in-out;
    transition: top .3s ease .3s,transform .15s ease-out .1s,-webkit-transform .15s ease-out .1s
}
.hamburger-menu:before{ 
    top: 0px;  
    height: 100%; 
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box; 
    background: #0080C8;
    height: 5px;
}
.dark .hamburger-menu:before{
    background-color: #fff;
}
body.open-popup .hamburger-menu:before{
    background-color: #fff;
}
.hamburger-menu:after{
    right: 0;
    width: 100%;
    background-color:#0080C8;
    height: 5px; 
    top: 25px 
}
.dark .hamburger-menu:after{
    background-color: #fff;
}
body.open-popup .hamburger-menu:after{
    background-color: #fff;
}

.hamburger-menu.opened:before, .hamburger-menu.opened:after{
    background-color:#0080C8;
    border: none;
    height: 5px; 
    right: auto; 
    width: 100%; 
    top: 50%;
    margin-top: -2.5px;
    bottom: auto;
    transition: top .15s ease .1s,transform .15s ease-out .3s,-webkit-transform .15s ease-out .3s
}
.dark .hamburger-menu.opened:before, .dark .hamburger-menu.opened:after{
    background-color: #0080C8;
}
body.open-popup .hamburger-menu.opened:before, body.open-popup .hamburger-menu.opened:after{
    background-color: #fff  ;
}
.hamburger-menu.opened:before{ 
    transform: rotate(45deg) 
}
.hamburger-menu.opened:after{ 
    transform: rotate(-45deg)
}
.hamburger-menu.opened span{
    opacity: 0;
    transition: opacity .2s ease-in-out .2s; 
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Boton */
.btn { 
    border-radius: 16px;
    border: 5px solid #000;
    background-color: rgb(212, 73, 103);
    color: #fff;
    padding: 8px 10px;
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-family: Roboto Mono; 
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    transition: all .3s;

}

.btn.secondary {   
    background-color: #1A2630;  
}
 
.btn.big {   
    padding: 20px 10px;
    font-size: 24px;
} 
body.dark.open-popup .btn.secondary{
    background-color: #0080C8;
}

.svg-button:hover svg .stroke{
    stroke: #22333F;
}
.svg-button:hover svg .fill{
    fill: #22333F;
}
.container-map{
    display: flex;
    justify-content: flex-end;

}
.container-map > svg{
    max-width: 80%;
    height: auto;
    transform: translate(4%, 0px)
}

/* Social media */
.social-media a:hover svg path{ 
    fill: #22333F; 
}
body.dark .social-media a:hover svg path{ 
    fill: #d44967; 
}

/* Container Youtube */
 
.container-youtube > div{
    height: 0;
     
	overflow: hidden;
	padding-bottom: 56.25%;
	padding-top: 30px;
	position: relative;
}
.container-youtube iframe {
    height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
  }

/* Switch */
.switch{
    display: inline-block;
    position: relative;
}
.switch input{
    visibility: hidden;
    width: 0;
    height: 0;
}
.switch label{
    cursor: pointer;
    text-indent: 9999px;
    width: 80px;
    height: 40px;
    display: block;
    background-color: #fff;
    border-radius: 40px;
    position: relative;
}
.switch label:after{
    content: '';
	position: absolute;
	top: 4px;
	left: 4px;
	width: 32px;
	height: 32px;
	background: #fff;
	border-radius: 32px;
	transition: 0.3s;
    border: 1px solid grey;
}
.switch input:checked + label{
    background-color: #1A2630;
}
.switch input:checked + label:after{
    left: calc(100% - 4px);
	transform: translateX(-100%);
} 
body.dark .switch label{
    background-color: #56819F;
}

body.dark.switch input:checked + label{
    background-color: #fff;
} 

/* Elementos del mapa */
.element-item{
    position: absolute;
    z-index: 10;
    display: inline-block;
    padding: 0;
    height: auto;
} 
 
#molinete {
    top: 60%;
    left: 65.9%;
    width: 6.9%;
}
/* #molinete .animate{  
    animation: example3 3s linear 0s infinite ;
}  */

#palms {
    top: 19.6%;
    left: 80.7%;
    width: 14.6%;
}

#alpacas {
    top: 32.3%;
    left: 55.5%;
    width: 14.6%;
}

#agricultores {
    top: 25.2%;
    left: 70.7%;
    width: 14%;
}

#boat {
    top: 58.7%;
    left: 47.8%;
    width: 9.6%;
    animation: animationBoat 3s linear 0s infinite alternate-reverse;
} 

#boat .to-animate{ 
    animation: animationBoatSailing 3s linear 0s infinite alternate-reverse;
}



#cactus {
    top: 23.9%;
    left: 49.8%;
    width: 15.7%;
}

#cascade {
    top: 13.61%;
    left: 63.4%;
    width: 10.4%;
} 

#constructor {
    top: 38.1%;
    left: 67.4%;
    width: 21.5%;
}

#friends{ 
    top: 10.7%;
    left: 48.1%;
    width: 11.52%; 
    animation: animateFriends 1.5s ease-in-out 0s alternate-reverse infinite;
} 

#penguin {
    top: 83.3%;
    left: 76.3%;
    width: 3.9%;
}

#pinos {
    top: 63.25%;
    left: 60%;
    width: 6.95%;
}

#surfer {
    top: 8.7%;
    left: 81.6%;
    width: 16.6%;
}

#surfer path:not(.ola){  
    animation: animateSurfer 2s ease-in-out 0s  alternate-reverse infinite;
}
#surfer .ola{
    animation: animateOla 2s ease-in-out 0s alternate-reverse infinite;
}
#panels {
    top: 51.18%;
    left: 58.1%;
    width: 9.8%;
}
#panels .panel-bg{
    animation: animatePanel 2s ease-in-out 0s alternate-reverse infinite;
}

@keyframes animationBoat {
    from {transform: rotate(7deg)} 
    to {transform: rotate(-7deg)}
}

@keyframes animationBoatSailing {
    from {transform: rotateY(0deg)} 
    to {transform: rotateY(22deg)}
}
@keyframes animateOla {
    from {transform: translateY(5px)} 
    to {transform: translateY(-3px)}
}
@keyframes animateSurfer { 
    from {transform: translateY(5px)  rotateZ(0) }
    to {transform: translateY(-12px) rotateZ(9deg) } 
}

@keyframes animateFriends {
    from {transform: rotate(-3deg);}
    to {transform: rotate(3deg);}
}

@keyframes animatePanel {
    /* from {fill: rgba(255, 255, 255, 1) }
    to {fill: rgba(255, 255, 255, 0.7) } */
    from {fill: #fff}
    to {fill: #e6e1e1}
}

@media (min-width: 640px) {
    
    .btn{
        width: auto;
    }
    .container-map > svg{
        max-width: 86%;
    }

    #molinete {
        top: 60.7%;
        left: 63.6%;
        width: 7.16%;
    } 
    
    #palms {
        top: 19.6%;
        left: 79.4%;
        width: 15%;
    }
    
    #alpacas {
        top: 32.3%;
        left: 51.9%;
        width: 15%;
    }
    
    #agricultores {
        top: 25.2%;
        left: 67.7%;
        width: 15.6%;
    }
    
    #boat{ 
        top: 58.7%;
        left: 44.4%;
        width: 10%; 
    }
     
    #cactus {
        top: 23.9%;
        left: 45.5%;
        width: 17%;
    }
    
    #cascade {
        top: 13.61%;
        left: 60%;
        width: 11.9%;
    } 
    
    #constructor {
        top: 38.2%;
        left: 64.9%;
        width: 23.1%;
    }
    
    #friends{ 
        top: 10.7%;
        left: 44%;
        width: 12.92%;  
    } 
    
    #penguin {
        top: 83.2%;
        left: 74.3%;
        width: 4.2%;
    }
    
    #pinos {
        top: 63.25%;
        left: 57%;
        width: 7.5%;
    }
    
    #surfer{  
        top: 8.7%;
        left: 80.1%;
        width: 17%;
    } 
    
    
    #panels {
        top: 51.18%;
        left: 55.1%;
        width: 10.6%;
    } 
}  
@media (max-width: 768px) {
    /* Container Youtube */
    .container-youtube > div{
        padding-bottom: 75%; /* Puedes ajustar este valor según tus necesidades */
    } 
}

@media screen and (min-width: 1024px) {
    #boat{ 
        top: 56%;
        left: 21.4%;
        width: 12%; 
    }
    #surfer{ 
        top: 7.8%;
        left: 63.6%;
        width: 17%; 
    }
    #panels{ 
        top: 49.18%;
        left: 33.6%;
        width: 12.6%;
    }
    #pinos{ 
        top: 61%;
        left: 36%;
        width: 8.77%;
    }
    #penguin{ 
        top: 80.4%;
        left: 56.2%;
        width: 4.8%;
    }
    #cactus{ 
        top: 22.7%;
        left: 22.9%;
        width: 19.3%;
    }
    #molinete{ 
        top: 58.4%;
        left: 43.6%;
        width: 8.35%; 
    }
    #alpacas{ 
        top: 30.6%;
        left: 29.9%;
        width: 18%;
    }
    #agricultores{ 
        top: 24%;
        left: 48.6%;
        width: 18.1%;
    }
    #cascade{ 
        top: 12.81%;
        left: 39.9%;
        width: 13.6%;
    }
    #palms{ 
        top: 18.4%;
        left: 61.9%;
        width: 18.1%; 
    }
    #friends{  
        top: 9.7%;
        left: 21%;
        width: 14.5%; 
    }
    #constructor{ 
        top: 36.7%;
        left: 45.7%;
        width: 26.4%;
    }
    .container-map > svg{
        transform: translate(-10%, -5px);
        max-width: 97%; 
    }   

    .bg-site{ 
        background: transparent url('../public/img/bg.svg') no-repeat;
        background-size: cover;
        background-position: 0 bottom;
    }
    body.dark .bg-site{
        background: transparent url('../public/img/bg-dark.svg') no-repeat;
        background-size: cover;
        background-position: 0 bottom;
    }
    /* Menu hamburguesa */ 
    .hamburger-menu span{ 
        background: #fff;    
    }
    .hamburger-menu:hover span,
    .container-hamburger:hover .hamburger-menu span,
    body.open-popup .container-hamburger:hover .hamburger-menu span { 
        background: #22333F;    
    }
    body.dark .container-hamburger:hover .hamburger-menu span { 
        background: #D44967 !important;    
    } 
     
    .hamburger-menu:before{  
        background: #fff; 
    } 
    .hamburger-menu:hover:before, 
    .container-hamburger:hover .hamburger-menu:before,
    body.open-popup .container-hamburger:hover .hamburger-menu:before{ 
        background: #22333F;    
    }
    
    body.dark .container-hamburger:hover .hamburger-menu:before{ 
        background: #D44967 !important;    
    } 
    .hamburger-menu:after{ 
        background-color:#fff; 
    }  
    .hamburger-menu:hover:after, 
    .container-hamburger:hover .hamburger-menu:after,
    body.open-popup .container-hamburger:hover .hamburger-menu:after { 
        background: #22333F;    
    }
    body.dark .container-hamburger:hover .hamburger-menu:after { 
        background: #D44967 !important;
    } 
    .hamburger-menu.opened:before, 
    .hamburger-menu.opened:after{
        background-color:#fff;  
    }  
    .hamburger-menu.opened:hover:before, 
    .container-hamburger:hover .hamburger-menu.opened:before,
    body.open-popup .container-hamburger:hover .hamburger-menu.opened:before{ 
        background: #22333F;    
    }
    body.dark .container-hamburger:hover .hamburger-menu.opened:before{ 
        background: #D44967 !important;
    }  

    .only-mobile{
        display: none;
    }
    .only-desktop{
        display: block;
    }
    .btn {  
        width: auto;
        padding: 7px 10px;
    } 
    .btn.big {   
        padding: 25px 40px;
        font-size: 32px;
    } 
    .btn:hover {   
        background-color: #1A2630;  
    }
    .btn.secondary:hover, body.dark.open-popup .btn.secondary:hover{   
        background-color: rgba(212, 73, 103, 1);  
    }
     
}

@media screen and (min-width: 1280px) {
    .container-map > svg{ 
        max-width: initial; 
    }    
     
}
